import React from 'react';
import {Typography} from '@/components/ui/typography';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog';
import {Button} from '@/components/ui/button';
import {ScrollArea} from '@/components/ui/scroll-area';

type CodeOfConductModalProps = {
    open: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const CodeOfConductModal = (props: CodeOfConductModalProps) => {
    const {onOpenChange, open} = props;

    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="w-full max-w-screen sm:max-w-screen-lg sm:w-2/3 lg:w-1/2">
                <DialogHeader>
                    <DialogTitle>As a member of Casehub</DialogTitle>
                </DialogHeader>
                <ScrollArea className="max-h-[80vh]">
                    <div>
                        <div className="grid gap-4 py-4">
                            <CodeOfConductSection title="Communication">
                                <Typography variant="p">
                                    In all my oral or written communication
                                    {' '}
                                    <strong>
                                        I respect
                                    </strong>
                                    {' '}
                                    other Casehub Members.
                                </Typography>
                                <Typography variant="p">
                                    As a sign of respect to other Members, I will
                                    {' '}
                                    <strong>fill my Casehub profile</strong>
                                    {' '}
                                    and upload my Photo.
                                </Typography>
                            </CodeOfConductSection>
                            <CodeOfConductSection title="Sessions">
                                <Typography variant="p">
                                    When participating in Casehub’s live group sessions (listed in “Schedule” section of
                                    the Site)
                                    {' '}
                                    <strong>I keep any specific person or organisation related information shared by
                                        other participants confidential.</strong>
                                    {' '}
                                    I can share the ideas, suggestions, or conclusions from the session only without
                                    revealing the identity of the person or related organisation.
                                </Typography>
                                <Typography variant="p">
                                    I give Casehub my
                                    {' '}
                                    <strong>permission to record the sessions</strong>
                                    {' '}
                                    where I participate,
                                    cut and edit the videos and post them on the Casehub website.
                                </Typography>
                                <Typography variant="p">
                                    In case, I decide after the recorded session that I don’t want some specific
                                    information to be shared,
                                    {' '}
                                    <strong>
                                        I express this clearly via e-mail to
                                        {' '}
                                        <a href="mailto:info@casehub.pro" className="link-primary">info@casehub.pro</a>
                                        {' '}
                                        within 24 hours after the session.
                                    </strong>
                                    {' '}
                                    I am aware of Casehub’s obligation not to use this information.
                                </Typography>
                            </CodeOfConductSection>
                            <CodeOfConductSection title="General">
                                <Typography variant="p">
                                    I will
                                    {' '}
                                    <strong>report any activity or contribution in Casehub that is unrespectful
                                        towards</strong>
                                    {' '}
                                    any Member or organisation
                                    related to any Mamber by sending an e-mail with supporting evidence to
                                    {' '}
                                    <a href="mailto:info@casehub.pro" className="link-primary">info@casehub.pro</a>
                                </Typography>
                                <Typography variant="p">
                                    I am aware that {' '} <strong>Casehub has a right to block the account of the
                                    member</strong>
                                    {' '}
                                    who have violated the Casehub’s {' '} <a href="/content/terms" target={'_blank'}
                                                                             rel={'nofollow noopener'}
                                                                             className="link-primary">Terms of
                                    Use</a> or the Code of Conduct.
                                </Typography>
                            </CodeOfConductSection>
                        </div>
                    </div>
                </ScrollArea>
                <DialogFooter>
                    <Button type="button" onClick={() => {
                        onOpenChange(false);
                    }}>Close</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
};

type CodeOfConductSectionProps = {
    title: string;
    children: React.ReactNode;
}
const CodeOfConductSection = ({children, title}: CodeOfConductSectionProps) => {
    return (
        <div className="p-4">
            <Typography variant="h5" className="mb-2">
                {title}
            </Typography>
            {children}
        </div>
    )
}

export default CodeOfConductModal;
