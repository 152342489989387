import {useLocalStorage} from './useLocalStorage';
import {useAuth} from '../auth';

export const useAssessmentFormStorage = (key?: string) => {
    const auth = useAuth();
    const userKey = auth?.user?.id || 0;
    const [answers, setAnswers] = useLocalStorage(`assessment-form-${key ? `${key}-` : ''}${userKey}`, {});

    return [answers, setAnswers]
}
