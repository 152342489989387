import React from 'react';
import Head from 'next/head';

export type SeoTagsConfig = {
    title?: string;
    description?: string;
    keywords?: string;
}

type SeoTagsProps = {
    config?: SeoTagsConfig
}

const SeoTags = (props?: SeoTagsProps) => {
    if (!props?.config) {
        return null;
    }

    return (
        <Head>
            {props.config.title ? (
                <>
                    <title>{props.config.title}</title>
                    <meta property='og:title' content={props.config.title}/>
                </>
            ) : null}
            {props.config.description ? (
                <>
                    <meta name="description" content={props.config.description} />
                    <meta property='og:description' content={props.config.description}/>
                </>
            ) : null}
            {props.config.keywords ? (
                <meta name="keywords" content={props.config.keywords} />
            ) : null}
            <meta property='og:image' content='//casehub.pro/og.png'/>
        </Head>
    )
};

export default SeoTags;
