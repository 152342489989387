import {Input} from '@/components/ui/input';
import React from 'react';
import * as z from 'zod';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/components/ui/form';
import {useLocalStorage} from "@/util/hooks/useLocalStorage";

const formSchema = z.object({
    firstName: z.string({
        required_error: "Please enter your first name",
    }),
    lastName: z.string({
        required_error: "Please enter your last name",
    }),
    email: z.string({
        required_error: "Please enter an email",
    }).min(1, { message: "This field has to be filled." }).email("This is not a valid email."),
    password: z.string({
        required_error: "You must specify a password",
    }).min(8, {
        message: "Password must have at least 8 characters",
    }),
    referrer: z.string().optional(),
});

type SignUpFormProps = {
    onSubmit: (values: z.infer<typeof formSchema>) => void;
    formId?: string;
}
export const SignUpForm = (props: SignUpFormProps) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema)
    });

    const [referral, , clearReferral] = useLocalStorage(`r`, null);

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        if (typeof props.onSubmit === 'function') {
            props.onSubmit(values);
            if (referral?.key) {
                clearReferral();
            }
        }
    }

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" id={props.formId}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <FormField
                            control={form.control}
                            name="firstName"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel>First name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="ex. John" {...field}/>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="lastName"
                            render={({field}) => (
                                <FormItem>
                                    <FormLabel>Last name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="ex. Doe"{...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                    </div>
                    <FormField
                        control={form.control}
                        name="email"
                        render={({field}) => (
                            <FormItem>
                                <FormLabel>E-mail</FormLabel>
                                <FormControl>
                                    <Input placeholder="ex. johndoe@gmail.com" {...field} />
                                </FormControl>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Password</FormLabel>
                                <FormControl>
                                    <Input type="password" autoComplete="new-password" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <input
                        type="hidden"
                        value={referral?.key || ''}
                        {...form.register("referrer")}
                    />
                </form>
            </Form>
        </>
    );
}
