import {cva, VariantProps} from 'class-variance-authority';
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import {cn} from '@/lib/utils';

const typographyVariants = cva(
    "",
    {
        variants: {
            variant: {
                default: "",
                h1: "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
                h2: "scroll-m-20 pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0",
                h3: "scroll-m-20 text-2xl font-semibold tracking-tight",
                h4: "scroll-m-20 text-xl font-semibold tracking-tight",
                h5: "scroll-m-20 text-lg font-semibold tracking-tight",
                h6: "scroll-m-20 text-sm font-semibold tracking-tight",
                p: "leading-7 [&:not(:first-child)]:mt-6",
                blockquote: "mt-6 border-l-2 pl-6 italic",
                lead: "text-xl text-muted-foreground",
                large: "text-lg font-semibold",
                small: "text-sm font-medium leading-none",
                muted: "text-xs text-muted-foreground",
                xs: "text-xs font-medium leading-none",
            },
        },
        defaultVariants: {
            variant: "default",
        },
    }
)

export interface TypographyProps
    extends React.HTMLAttributes<HTMLParagraphElement>,
        VariantProps<typeof typographyVariants> {
    asChild?: boolean
}

const Typography = React.forwardRef<HTMLParagraphElement, TypographyProps>(
    ({ className, variant, asChild = false, ...props }, ref) => {
        const Comp = asChild ? Slot : "p"
        return (
            <Comp
                className={cn(typographyVariants({ variant, className }))}
                ref={ref}
                {...props}
            />
        )
    }
)
Typography.displayName = "Typography"

export { Typography, typographyVariants }
