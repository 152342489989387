import {Input} from '@/components/ui/input';
import React from 'react';
import * as z from 'zod';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/components/ui/form';

const formSchema = z.object({
    email: z.string({
        required_error: "Please enter an email",
    }).min(1, { message: "This field has to be filled." }).email("This is not a valid email.")
});

formSchema.required({
    email: true
});

type PasswordForgotFormProps = {
    onSubmit: (values: z.infer<typeof formSchema>) => void;
    formId?: string;
}
export const PasswordForgotForm = (props: PasswordForgotFormProps) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema)
    })

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        if (typeof props.onSubmit === 'function') {
            props.onSubmit(values);
        }
    }

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" id={props.formId}>
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </>
    );
}
