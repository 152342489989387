export function addGtmEvent(eventName, eventProps) {
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            ...eventProps,
            event: eventName
        });
    }
}

export const events = {
    verificationComplete: 'verificationComplete',
    registration: 'registration',
    login: 'login'
}
