import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import React from "react";

interface RecaptchaProps {
    children: React.ReactNode;
}

export const RecaptchaComponent = <P extends object>(Component: React.ComponentType<P>) => {
    const Recaptcha = ({ children }: RecaptchaProps) => {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}>
                {children}
            </GoogleReCaptchaProvider>
        );
    };
    return class Higher extends React.Component<P> {
        render() {
            return (
                <Recaptcha>
                    <Component {...this.props as P} />
                </Recaptcha>
            );
        }
    };
};
