import React, {useState} from "react";
import Link from 'next/link';
import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from './ui/card';
import {Button, buttonVariants} from './ui/button';
import {useRouter} from 'next/router';
import {forceInternalPath} from '@/util/util';
import {useAuth} from '@/util/auth';
import {useAssessmentFormStorage} from '@/util/hooks/useAssessmentFormStorage';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {addGtmEvent, events} from '@/util/gmt';
import {getDefaultTimezone} from '@/util/timezone';
import {SignInForm} from '@/components/forms/SignInForm';
import {RecaptchaComponent} from '@/components/Recaptcha';
import {PasswordForgotForm} from '@/components/forms/PasswordForgotForm';
import {PasswordResetForm} from '@/components/forms/PasswordResetForm';
import {SignUpForm} from '@/components/forms/SignUpForm';
import CodeOfConductModal from '@/components/CodeOfConductModal';
import {Typography} from '@/components/ui/typography';
import {cn} from '@/lib/utils';

export enum AuthSectionType {
    SignIn = 'signin',
    SignUp = 'signup',
    ForgotPassword = 'forgotpass',
    ResetPassword = 'resetpass'
}

type AuthSectionProps = {
    afterAuthPath?: string;
    type: AuthSectionType;
}

type SignInFormValues = {
    email: string;
    password: string;
}

type SignUpFormValues = {
    email: string;
    password: string;
    timezone?: string;
    referrer?: string;
}

type ForgotPasswordFormValues = {
    email: string;
}

type ResetPasswordFormValues = {
    password: string;
    confirmPassword: string;
}

type FormValues = SignInFormValues | SignUpFormValues | ForgotPasswordFormValues | ResetPasswordFormValues;

type SubmitHandler = (data: FormValues & {
    recaptchaToken?: string;
}) => Promise<void>;

type SubmitHandlersByType = {
    [K in AuthSectionType]: SubmitHandler;
};

export const useAuthSubmitHandlers = ({afterAuthPath, onError, type}: {
    afterAuthPath?: string;
    onError?: (error: any) => void;
    type: AuthSectionType;
}) => {
    const router = useRouter();
    const [answers] = useAssessmentFormStorage();
    const handleAuth = () => {
        router.push(forceInternalPath(afterAuthPath));
    };
    const auth = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const submitHandlersByType: SubmitHandlersByType = {
        signin: async (data) => {
            if (!('password' in data) || !('email' in data)) {
                return;
            }

            await auth.signin({username: data.email, password: data.password, recaptchaToken: data.recaptchaToken}).then((user: {
                id: string;
            }) => {
                addGtmEvent(events.login, {
                    authenticationMethod : 'email',
                    userId: user.id
                });

                handleAuth();
            });
        },
        signup: async (data) => {
            if (!('password' in data) || !('email' in data)) {
                return;
            }

            let selfAssessmentResults;

            if (answers) {
                selfAssessmentResults = Object.values(answers).filter(Boolean);
            }

            await auth.signup({
                timezone: getDefaultTimezone(),
                ...data,
                codeOfConduct: true,
                terms: true,
                keepPrivate: true,
                assessmentData: selfAssessmentResults || undefined,
            }).then(() => {
                const email = data.email;
                if (email) {
                    addGtmEvent(events.registration, {
                        authenticationMethod : 'email'
                    });
                    router.replace(`/verify?email=${encodeURIComponent(email)}`);
                }
            });
        },
        forgotpass: async (data) => {
            if (!('email' in data)) {
                return;
            }
            await auth.forgotpass({
                ...data
            }).then((response: {
                status: string;
            }) => {
                const email = data.email;
                if (email && response.status === 'SUCCESS') {
                    router.replace(`/passforgotten?email=${encodeURIComponent(email)}`, undefined, { shallow: true });
                }
            });
        },
        resetpass: async (data) => {
            if (!('password' in data) || !('confirmPassword' in data)) {
                return;
            }
            const {hash} = router.query;
            await auth.resetpass({
                hash: hash,
                new_password1: data.password,
                new_password2: data.confirmPassword,
                recaptcha_token: data.recaptchaToken
            }).then((response: {
                status: string;
            }) => {
                if (response.status === 'SAVED') {
                    router.replace('/');
                }
            });
        }
    }

    // typ should be key of submitHandlersByType
    return (formData: FormValues) => {
        if (!executeRecaptcha) {
            return;
        }

        executeRecaptcha(type).then((token) => {
            return submitHandlersByType[type]({
                ...formData,
                recaptchaToken: token
            }).catch(onError);
        })
    };
};

type AlertMessageProps = {
    message: string;
    type: string;
}

function AuthSection(props: AuthSectionProps) {
    const [formAlert, setFormAlert] = useState<AlertMessageProps | null>( null);

    const handleFormAlert = (data: AlertMessageProps) => {
        setFormAlert(data);
    };
    const [isCodeOfConductModalOpen, setIsCodeOfConductModalOpen] = useState(false);

    const onSubmit = useAuthSubmitHandlers({
        afterAuthPath: props.afterAuthPath,
        onError: handleFormAlert,
        type: props.type
    });
    const formId = `form_${props.type}`;

    const allTypeValues: {
        [key in AuthSectionProps['type']]: {
            title: string;
            subtitleText?: string;
            subtitleLink?: string;
            subtitleLinkText?: string;
            buttonText: string;
        }
    } = {
        signin: {
            title: 'Welcome!',
            subtitleText: 'Don\'t have and account yet?',
            subtitleLink: '/auth/signup',
            subtitleLinkText: 'Sign up',
            buttonText: 'Log in',
        },
        signup: {
            title: 'Try Casehub free for 14 days!',
            buttonText: 'Create an account',
            subtitleText: 'Full access. No credit cards required.',
        },
        forgotpass: {
            title: 'Reset password',
            buttonText: 'Reset'
        },
        resetpass: {
            title: 'Update password',
            buttonText: 'Submit'
        },
    };

    const currentType = props.type;
    const typeValues = allTypeValues[currentType];

    if (!typeValues) {
        return null;
    }

    return (
        <div className="container">
            <div className="flex items-center justify-center py-12">
                <Card className="w-full max-w-screen sm:w-1/2 lg:w-1/3">
                    <CardHeader className="space-y-1">
                        <CardTitle className="text-2xl">{typeValues.title}</CardTitle>
                        <CardDescription>
                            {typeValues.subtitleText}
                            {' '}
                            {typeValues.subtitleLink ? (
                                <Link href={typeValues.subtitleLink} passHref>
                                    <Button asChild variant="link" className="p-0">
                                        <a>{typeValues.subtitleLinkText}</a>
                                    </Button>
                                </Link>
                            ) : null}
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                        {props.type === AuthSectionType.SignIn && (<SignInForm onSubmit={onSubmit} formId={formId} />)}
                        {props.type === AuthSectionType.SignUp && (<SignUpForm onSubmit={onSubmit} formId={formId} />)}
                        {props.type === AuthSectionType.ForgotPassword && (<PasswordForgotForm onSubmit={onSubmit} formId={formId} />)}
                        {props.type === AuthSectionType.ResetPassword && (<PasswordResetForm onSubmit={onSubmit} formId={formId} />)}
                    </CardContent>
                    <CardFooter>
                        <div className="flex flex-col gap-2 w-full">
                            <Button type="submit" className="w-full" form={formId}>{typeValues.buttonText}</Button>

                            {!!formAlert?.message && (
                                <div>
                                    <p className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-destructive">
                                        {formAlert.message}
                                    </p>
                                </div>
                            )}

                            {props.type === AuthSectionType.SignUp ? (
                                <div className="w-full mt-8 text-center grid gap-2">
                                    <Typography variant="muted" asChild>
                                        <div>
                                            By signing up, you accept our
                                            {' '}
                                            <Link href="/content/terms" passHref>
                                                <a className={cn(buttonVariants({
                                                    variant: 'link',
                                                }), 'p-0 h-auto')} target={'_blank'} rel={'nofollow noopener'}>Terms of use</a>
                                            </Link>
                                            {' '}
                                            and
                                            {' '}
                                            <Button
                                                variant="link"
                                                className="p-0 h-auto"
                                                onClick={() => {
                                                    setIsCodeOfConductModalOpen(true)
                                                }}
                                            >
                                                Code of Conduct
                                            </Button>
                                        </div>
                                    </Typography>
                                    <Typography variant="muted">
                                        Already have an account?
                                        {' '}
                                        <Link href="/auth/signin" passHref>
                                            <a className={cn(buttonVariants({
                                                variant: 'link',
                                            }), 'p-0 inline')} target={'_blank'} rel={'nofollow noopener'}>Log in.</a>
                                        </Link>
                                    </Typography>
                                    <CodeOfConductModal
                                        open={isCodeOfConductModalOpen}
                                        onOpenChange={setIsCodeOfConductModalOpen}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}

export default RecaptchaComponent(AuthSection);
