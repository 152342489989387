import {Input} from '@/components/ui/input';
import React from 'react';
import * as z from 'zod';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from '@/components/ui/form';

const formSchema = z.object({
    password: z.string({
        required_error: "You must specify a password",
    }).min(8, {
        message: "Password must have at least 8 characters",
    }),
    confirmPassword: z.string({
        required_error: "You must specify a password",
    }).min(8, {
        message: "Password must have at least 8 characters",
    }),
}).refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
});

type NewCaseFormProps = {
    onSubmit: (values: z.infer<typeof formSchema>) => void;
    formId?: string;
}
export const PasswordResetForm = (props: NewCaseFormProps) => {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema)
    })

    const onSubmit = (values: z.infer<typeof formSchema>) => {
        if (typeof props.onSubmit === 'function') {
            props.onSubmit({
                password: values.password,
                confirmPassword: values.confirmPassword
            });
        }
    }

    return (
        <>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4" id={props.formId}>
                    <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>New password</FormLabel>
                                <FormControl>
                                    <Input type="password" autoComplete="new-password" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="confirmPassword"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Repeat password</FormLabel>
                                <FormControl>
                                    <Input type="password" autoComplete="new-password" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                </form>
            </Form>
        </>
    );
}
